import React from 'react';
import './splash_visualization.css';
import { useD3 } from './useD3';
import * as d3 from 'd3';

function SplashVisualization() {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const ref = useD3(
    (svg, interval_obj) => {

      // Store a reference to the svg element's width and height
      var width = +svg.attr("width"),
          height = +svg.attr("height");

      /* Specify the block size and then calculate the
         board width and board height */
      var block_size = 20;
      var board_width = Math.floor(width/block_size);
      var board_height = Math.floor(height/block_size);

      // Create the group element for the board
      var board_area = svg.append("g")
        .attr("class", "board_area");

      /* Create the board area itself, which takes up the
         entire SVG dimensions */
      board_area.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .attr("fill", "#fff");

      /* The blocks will be drawn into this group */
      var static_group = svg.append("g")
        .attr("class", "static_group");

      /* Declare three variables that keep track of
         the visualization state:

           board_state: a two-dimensional array that
           tracks where there are blocks on the board;
           values of -1 mean that the board is empty
           in that space and all other values mean
           that the space is occupied by a block

           static_blocks: an array that holds the
           "static" blocks (the blocks that have
           reachced their final place on the board)

           block_counter: a counter that is incremented
           every time a block is transitioned from being
           "active" to "static" (see below) */
      var board_state,
          static_blocks,
          block_counter;

      // Reset the board state and add a new active piece
      reset_board();

      // Draw the board in its current state
      draw_board();

      /* Create an interval that will trigger every
         1.5 seconds to update the visualization */
      var interval_length = 1500;
      var interval_num = setInterval(update_visualization, interval_length);
      interval_obj.interval_id = interval_num;

      /* FUNCTION: reset_board()
         This function initializes the board state to be
         blank, clears the arrays that keep track of the
         "active" and "static" pieces/blocks, resets the
         block counter, and then adds a new active piece
         to the top of the board */
      function reset_board() {

        /* Initialize a two-dimensional array with the
           given dimensions of the board; fill the array
           with values of -1, which represent empty spaces */
        board_state = new Array(board_width);
        for(let i = 0; i < board_width; i++) {
          board_state[i] = new Array(board_height).fill(-1);
        }

        // Clear the array that keeps track of the static blocks
        static_blocks = [];

        // Reset the block counter to zero
        block_counter = 0;

        /* Create new objects in the static_blocks array that
           represent each of the four blocks in the currently
           active piece */
        for(let i = 0; i < board_width; i++) {

          let col_max = width < 400 ? i+1 : Math.ceil(Math.random() * board_height);
          for(let j = 0; j < col_max; j++) {

            let new_piece_type = Math.floor(Math.random() * (width < 400 ? 4 : 5));
            let new_piece_color = "";

            // Set the piece's type and color based on the integer
            switch (new_piece_type) {
              case 0:
                new_piece_color = "#2c435e";
                break;
              case 1:
                new_piece_color = "#fcae1e";
                break;
              case 2:
                new_piece_color = "#666";
                break;
              case 3:
                new_piece_color = "#c4d67a";
                break;
              case 4:
                new_piece_color = "white";
                break;
            }

            /* Create a new object that stores the block's position
               and color; the ID (unique across all blocks on the
               board) will be used to identify this object later
               when clearing lines */
            let new_piece = {
              id: block_counter,
              position: [i, board_height-j-1],
              color: new_piece_color
            };

            // Add the object to the static_blocks array
            static_blocks.push(new_piece);

            /* Record the ID in the board_state array, which allows
               this object to be found later and also signals that
               this space on the board is filled */
            board_state[i][board_height-j-1] = block_counter;

            /* Increment the block counter to be used in
               assigning the next unique ID */
            block_counter += 1;

          }

        }

      }

      /* FUNCTION: draw_board()
         This functions draws or redraws the board
         given the current game state */
      function draw_board() {

        /* Create the transition to use when changing the
           block colors */
        const t2 = svg.transition().duration(500);

        // Add or update the blocks
        static_group.selectAll("g")
          .data(static_blocks, d => d.id)
          .join(
            enter => {

              // Add the overall groups for the static blocks
              let block_group = enter.append("g")
                .attr("transform", (d,i) =>
                        "translate(" +
                        (d.position[0]*block_size + block_size/2) + "," +
                        (d.position[1]*block_size + block_size/2) + ")");

              // Add the circles for the static blocks
              block_group.append("circle")
                .attr("r", block_size/2 - 2)
                .attr("fill", (d,i) => d.color)
                .attr("stroke-width", 0);

            },
            update => {

              // Update the positions of the groups for the static blocks
              update.transition(t2)
                .attr("transform", (d,i) =>
                        "translate(" +
                        (d.position[0]*block_size + block_size/2) + "," +
                        (d.position[1]*block_size + block_size/2) + ")");

              update.select("circle")
                .transition(t2)
                .attr("fill", (d,i) => d.color);

            },
            exit => exit.remove()
          );

      }

      /* FUNCTION: update_visualization()
         This function is called every half-second to
         update the game state and redraw the board */
      function update_visualization() {

        for(let i = 0; i < static_blocks.length; i++) {

          let new_piece_type = Math.floor(Math.random() * (width < 400 ? 4 : 5));
          let new_piece_color = "";

          // Set the piece's type and color based on the integer
          switch (new_piece_type) {
            case 0:
              new_piece_color = "#2c435e";
              break;
            case 1:
              new_piece_color = "#fcae1e";
              break;
            case 2:
              new_piece_color = "#666";
              break;
            case 3:
              new_piece_color = "#c4d67a";
              break;
            case 4:
              new_piece_color = "white";
              break;
          }

          // When the match is found, remove the object from static_blocks
          static_blocks[i].color = new_piece_color;
        }

        // Redraw the board
        draw_board();

      }

    },
    [windowSize]
  );

  return (
    <svg ref={ref} width={windowSize.width <= 1000 ? windowSize.width*0.8 : windowSize.width*0.5} height="300"></svg>
  );
}

export default SplashVisualization;
